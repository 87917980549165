import { useRequest } from '~/repositories'

export const getOffer = (offerId) => {
	return useRequest('get', `/v4/company/offers/${offerId}/detail`)
}
export const getCandidateNotes = (idOffer, idCandidate) => {
	return useRequest('get', `/v4/company/offers/${idOffer}/profile/${idCandidate}/company-notes`)
}

export const postCandidateNote = (idOffer, idCandidate, params) => {
	return useRequest('post', `/v4/company/offers/${idOffer}/profile/${idCandidate}/company-notes`, params)
}

export const getCandidatesAi = (offerId, params) => {
	return useRequest('get', `/v4/company/offers/${offerId}/ia`, { params })
}

export const getDashboardDetails = (idOffer) => {
	return useRequest('get', `/v4/company/offers/${idOffer}/dashboard/detail/`)
}

export const getCandidatesAiTop = (offerId, params) => {
	return useRequest('get', `/v4/company/offers/${offerId}/ia/top`, { params })
}

export const getCandidatesRequest = (offerId, params) => {
	return useRequest('get', `/v4/company/offers/${offerId}/requests`, { params })
}

export const getCandidatesInterested = (offerId, params) => {
	return useRequest('get', `/v4/company/offers/${offerId}/interested`, { params })
}

export const getCandidatesTrs = (offerId, params) => {
	return useRequest('get', `/v4/company/offers/${offerId}/trs`, { params })
}

export const getPipelineCandidates = (offerId, params) => {
	return useRequest('get', `/v4/company/offers/${offerId}/pipeline`, { params })
}

export const getPipelineColumn = (offerId, params) => {
	return useRequest('get', `/v4/company/offers/${offerId}/pipeline/show`, { params })
}

export const postUpdateOffer = (offerId, params) => {
	return useRequest('post', `/v4/company/offers/${offerId}`, params)
}

export default {
	getCandidateNotes,
	postCandidateNote,
	getCandidatesAi,
	getCandidatesAiTop,
	getCandidatesRequest,
	getCandidatesInterested,
	getCandidatesTrs,
	getPipelineCandidates,
	getPipelineColumn,
	postUpdateOffer,
	getOffer,
	getDashboardDetails,
}
