import tracer from '~/tracking/provider'
import $tracking from '~/tracking/utils/storage'
import { getNameElement } from '~/tracking/utils/dom'
import { useOfferStore } from '~/store/offer/offer'
import { useAuthStore } from '~/store/auth'

const paramsCandidate = (candidate) => {
	const { $date } = useNuxtApp()
	const route = useRoute()
	const offerStore = useOfferStore()
	const authStore = useAuthStore()

	const { component_type, total_candidates_offer, total_candidates_process } =
		$tracking.storage.get('candidateWithinOffer')

	const properties = {
		candidate_data: {
			id: candidate?.match_user_id || candidate?.id || '',
			developer_types: candidate?.developer_types || '',
			stacks: candidate?.stacks || '',
			country: candidate?.residency_country || '',
			min_salary: candidate?.min_salary || '',
			max_salary: candidate?.max_salary || '',
			money_type: candidate?.money_type || '',
			english_proficiency: candidate?.english_proficiency_data || candidate?.english_proficiency || '',
			work_modalities: candidate?.work_modalities || '',
			work_schedules: candidate?.work_schedules || '',
		},
		candidate_status: candidate?.status_label || '',
		candidate_source: route.query?.candidate_source || 'direct',
		candidate_hv: candidate?.stacks?.some((stack) => stack.score > 60),
		candidate_position: route.query?.candidate_position ? Number(route.query.candidate_position) : 0,
		offer_plan: authStore.user?.business?.active_subscription?.label || '',
		component_type,
		total_candidates_offer,
		total_candidates_process,
	}

	if (route.params?.idoffer) {
		properties.offer_status = offerStore.offer.status_label || ''

		const offerPublishedAt = offerStore.offer.published_at
		properties.offer_activity_days = offerPublishedAt ? $date().diff(offerPublishedAt, 'days') : null
	}

	return properties
}

export default {
	candidateClicked(candidate, element) {
		const params = {
			card_element: getNameElement(element, 'data-tracking') || 'table',
			...paramsCandidate(candidate),
		}

		tracer.track('candidate_clicked', params)
	},

	candidateLoaded(candidate) {
		const params = {
			...paramsCandidate(candidate),
		}

		tracer.track('candidate_loaded', params)
	},

	candidateCvClicked(view, candidate, type) {
		const params = {
			...paramsCandidate(candidate),
			view,
			type,
		}

		tracer.track('candidate_cv_clicked', params)
	},

	candidateWhatsappClicked(view, candidate) {
		const params = {
			...paramsCandidate(candidate),
			view,
		}

		tracer.track('candidate_whatsapp_clicked', params)
	},

	candidateLinkedinClicked(view, candidate) {
		const params = {
			...paramsCandidate(candidate),
			view,
		}

		tracer.track('candidate_linkedin_clicked', params)
	},
}
