import revive_payload_client_d4eK1S6fyF from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5lcSRzjLoV from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jRRwZUiAby from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yQMzHaDqPT from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_RUlZFNYUoZ from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_OQ5WmJGLbX from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.12.0_typescript@5.3.3_vue@3.4.8/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1250695087/src/frontend-hire/.nuxt/components.plugin.mjs";
import prefetch_client_imGsiOPXwn from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import modals_p3Nyuo5zaI from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/@nuxt+ui@2.14.1_axios@1.6.7_nuxt@3.10.3_rollup@4.12.0_vite@5.1.4_vue@3.4.8/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_o8qKHqgzNF from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/@nuxt+ui@2.14.1_axios@1.6.7_nuxt@3.10.3_rollup@4.12.0_vite@5.1.4_vue@3.4.8/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_0GUAlM6s5X from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_rollup@4.12.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_XezWe7Zx24 from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.12.0_vue@3.4.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_jryODpkU8p from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_axios_trfC49DPAq from "/codebuild/output/src1250695087/src/frontend-hire/plugins/01.axios.ts";
import _02_auth_kllCetJ9Sb from "/codebuild/output/src1250695087/src/frontend-hire/plugins/02.auth.ts";
import _03_requestRepository_FW7ZZoIDXJ from "/codebuild/output/src1250695087/src/frontend-hire/plugins/03.requestRepository.ts";
import _04_featureFlags_a1Cth6iBUN from "/codebuild/output/src1250695087/src/frontend-hire/plugins/04.featureFlags.ts";
import _05_tracking_client_geGWrgXAYH from "/codebuild/output/src1250695087/src/frontend-hire/plugins/05.tracking.client.ts";
import _06_dates_KRCiNSk3xi from "/codebuild/output/src1250695087/src/frontend-hire/plugins/06.dates.ts";
import _07_subscription_PLVAVyZUaD from "/codebuild/output/src1250695087/src/frontend-hire/plugins/07.subscription.ts";
import datadog_client_cfAAU11B0P from "/codebuild/output/src1250695087/src/frontend-hire/plugins/datadog.client.ts";
import maska_UHaKf2z1iQ from "/codebuild/output/src1250695087/src/frontend-hire/plugins/maska.ts";
import socket_xW42qVsBqW from "/codebuild/output/src1250695087/src/frontend-hire/plugins/socket.ts";
import vCalendar_jfuLUQ1Ymq from "/codebuild/output/src1250695087/src/frontend-hire/plugins/vCalendar.ts";
import vueToast_client_LXSTX0Nibh from "/codebuild/output/src1250695087/src/frontend-hire/plugins/vueToast.client.ts";
import vueTooltip_7qaORb5I8e from "/codebuild/output/src1250695087/src/frontend-hire/plugins/vueTooltip.ts";
export default [
  revive_payload_client_d4eK1S6fyF,
  unhead_5lcSRzjLoV,
  router_jRRwZUiAby,
  payload_client_yQMzHaDqPT,
  check_outdated_build_client_RUlZFNYUoZ,
  plugin_vue3_OQ5WmJGLbX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_imGsiOPXwn,
  modals_p3Nyuo5zaI,
  colors_o8qKHqgzNF,
  plugin_client_0GUAlM6s5X,
  i18n_XezWe7Zx24,
  chunk_reload_client_jryODpkU8p,
  _01_axios_trfC49DPAq,
  _02_auth_kllCetJ9Sb,
  _03_requestRepository_FW7ZZoIDXJ,
  _04_featureFlags_a1Cth6iBUN,
  _05_tracking_client_geGWrgXAYH,
  _06_dates_KRCiNSk3xi,
  _07_subscription_PLVAVyZUaD,
  datadog_client_cfAAU11B0P,
  maska_UHaKf2z1iQ,
  socket_xW42qVsBqW,
  vCalendar_jfuLUQ1Ymq,
  vueToast_client_LXSTX0Nibh,
  vueTooltip_7qaORb5I8e
]