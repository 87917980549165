import { default as billingXVnlqByDbSMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/billing.vue?macro=true";
import { default as companyJlxMtAJNDGMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/company.vue?macro=true";
import { default as planVbQKK5ZR9eMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/plan.vue?macro=true";
import { default as index3hdmiMpDzGMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/account/index.vue?macro=true";
import { default as _91token_93b33uVrHfzCMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/auth/reset-password/[token].vue?macro=true";
import { default as indexf0WPcU30WRMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/auth/reset-password/index.vue?macro=true";
import { default as indexjWKdxe5d59Meta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/[idcandidate]/index.vue?macro=true";
import { default as best_45by_45roleIKR1cmRzyiMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/best-by-role.vue?macro=true";
import { default as indexObHGO5SpWPMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/index.vue?macro=true";
import { default as scheduledO3ZKUb3oLRMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/demo/scheduled.vue?macro=true";
import { default as indexaX3nWQC4jEMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/index.vue?macro=true";
import { default as login2fo64k5CpMMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/login.vue?macro=true";
import { default as logoutGndBA6Du8eMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/logout.vue?macro=true";
import { default as index6Sbsrt160mMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offer/create/index.vue?macro=true";
import { default as createiY7zIgapQAMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offer/create.vue?macro=true";
import { default as import8plOFF3hE6Meta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offer/import.vue?macro=true";
import { default as detailsC1dYINHyy1Meta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue?macro=true";
import { default as index78DLXdEdDJMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue?macro=true";
import { default as technical_45requirements5Z8U3BbifEMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue?macro=true";
import { default as editCNLDK2SjREMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit.vue?macro=true";
import { default as detailsp4N7MopTWaMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/details.vue?macro=true";
import { default as indexLQkSq6ehH6Meta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/index.vue?macro=true";
import { default as pipelinewuRQglROUXMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue?macro=true";
import { default as searchs7pxfWV8YbMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/search.vue?macro=true";
import { default as indexOlV1yCsVcRMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index.vue?macro=true";
import { default as password_45resetrS581YoNUiMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/password-reset.vue?macro=true";
import { default as indexhmOGlGIOf9Meta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/register/index.vue?macro=true";
import { default as password_45setupaHJ7neEt5eMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/register/password-setup.vue?macro=true";
import { default as profile_45setup3PUTPOtDACMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/register/profile-setup.vue?macro=true";
import { default as buyejhf227qrJMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/buy.vue?macro=true";
import { default as payment_45methodFleoxNjRAaMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/payment-method.vue?macro=true";
import { default as successfulsdY84ZSlxlMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/successful.vue?macro=true";
import { default as updateS6a58Nu8pyMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/update.vue?macro=true";
import { default as indexIHIzZjG7CoMeta } from "/codebuild/output/src1250695087/src/frontend-hire/pages/users/index.vue?macro=true";
import { default as nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta } from "/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: index3hdmiMpDzGMeta?.name ?? "account___es",
    path: index3hdmiMpDzGMeta?.path ?? "/account",
    meta: index3hdmiMpDzGMeta || {},
    alias: index3hdmiMpDzGMeta?.alias || [],
    redirect: index3hdmiMpDzGMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/account/index.vue").then(m => m.default || m),
    children: [
  {
    name: billingXVnlqByDbSMeta?.name ?? "account-index-billing___es",
    path: billingXVnlqByDbSMeta?.path ?? "billing",
    meta: billingXVnlqByDbSMeta || {},
    alias: billingXVnlqByDbSMeta?.alias || [],
    redirect: billingXVnlqByDbSMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/billing.vue").then(m => m.default || m)
  },
  {
    name: companyJlxMtAJNDGMeta?.name ?? "account-index-company___es",
    path: companyJlxMtAJNDGMeta?.path ?? "company",
    meta: companyJlxMtAJNDGMeta || {},
    alias: companyJlxMtAJNDGMeta?.alias || [],
    redirect: companyJlxMtAJNDGMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/company.vue").then(m => m.default || m)
  },
  {
    name: planVbQKK5ZR9eMeta?.name ?? "account-index-plan___es",
    path: planVbQKK5ZR9eMeta?.path ?? "plan",
    meta: planVbQKK5ZR9eMeta || {},
    alias: planVbQKK5ZR9eMeta?.alias || [],
    redirect: planVbQKK5ZR9eMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index3hdmiMpDzGMeta?.name ?? "account___en",
    path: index3hdmiMpDzGMeta?.path ?? "/en/account",
    meta: index3hdmiMpDzGMeta || {},
    alias: index3hdmiMpDzGMeta?.alias || [],
    redirect: index3hdmiMpDzGMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/account/index.vue").then(m => m.default || m),
    children: [
  {
    name: billingXVnlqByDbSMeta?.name ?? "account-index-billing___en",
    path: billingXVnlqByDbSMeta?.path ?? "billing",
    meta: billingXVnlqByDbSMeta || {},
    alias: billingXVnlqByDbSMeta?.alias || [],
    redirect: billingXVnlqByDbSMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/billing.vue").then(m => m.default || m)
  },
  {
    name: companyJlxMtAJNDGMeta?.name ?? "account-index-company___en",
    path: companyJlxMtAJNDGMeta?.path ?? "company",
    meta: companyJlxMtAJNDGMeta || {},
    alias: companyJlxMtAJNDGMeta?.alias || [],
    redirect: companyJlxMtAJNDGMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/company.vue").then(m => m.default || m)
  },
  {
    name: planVbQKK5ZR9eMeta?.name ?? "account-index-plan___en",
    path: planVbQKK5ZR9eMeta?.path ?? "plan",
    meta: planVbQKK5ZR9eMeta || {},
    alias: planVbQKK5ZR9eMeta?.alias || [],
    redirect: planVbQKK5ZR9eMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/account/index/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91token_93b33uVrHfzCMeta?.name ?? "auth-reset-password-token___es",
    path: _91token_93b33uVrHfzCMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93b33uVrHfzCMeta || {},
    alias: _91token_93b33uVrHfzCMeta?.alias || [],
    redirect: _91token_93b33uVrHfzCMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93b33uVrHfzCMeta?.name ?? "auth-reset-password-token___en",
    path: _91token_93b33uVrHfzCMeta?.path ?? "/en/auth/reset-password/:token()",
    meta: _91token_93b33uVrHfzCMeta || {},
    alias: _91token_93b33uVrHfzCMeta?.alias || [],
    redirect: _91token_93b33uVrHfzCMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexf0WPcU30WRMeta?.name ?? "auth-reset-password___es",
    path: indexf0WPcU30WRMeta?.path ?? "/auth/reset-password",
    meta: indexf0WPcU30WRMeta || {},
    alias: indexf0WPcU30WRMeta?.alias || [],
    redirect: indexf0WPcU30WRMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexf0WPcU30WRMeta?.name ?? "auth-reset-password___en",
    path: indexf0WPcU30WRMeta?.path ?? "/en/auth/reset-password",
    meta: indexf0WPcU30WRMeta || {},
    alias: indexf0WPcU30WRMeta?.alias || [],
    redirect: indexf0WPcU30WRMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexjWKdxe5d59Meta?.name ?? "candidates-idcandidate___es",
    path: indexjWKdxe5d59Meta?.path ?? "/candidates/:idcandidate()",
    meta: indexjWKdxe5d59Meta || {},
    alias: indexjWKdxe5d59Meta?.alias || [],
    redirect: indexjWKdxe5d59Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/[idcandidate]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjWKdxe5d59Meta?.name ?? "candidates-idcandidate___en",
    path: indexjWKdxe5d59Meta?.path ?? "/en/candidates/:idcandidate()",
    meta: indexjWKdxe5d59Meta || {},
    alias: indexjWKdxe5d59Meta?.alias || [],
    redirect: indexjWKdxe5d59Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/[idcandidate]/index.vue").then(m => m.default || m)
  },
  {
    name: best_45by_45roleIKR1cmRzyiMeta?.name ?? "candidates-best-by-role___es",
    path: best_45by_45roleIKR1cmRzyiMeta?.path ?? "/candidates/best-by-role",
    meta: best_45by_45roleIKR1cmRzyiMeta || {},
    alias: best_45by_45roleIKR1cmRzyiMeta?.alias || [],
    redirect: best_45by_45roleIKR1cmRzyiMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/best-by-role.vue").then(m => m.default || m)
  },
  {
    name: best_45by_45roleIKR1cmRzyiMeta?.name ?? "candidates-best-by-role___en",
    path: best_45by_45roleIKR1cmRzyiMeta?.path ?? "/en/candidates/best-by-role",
    meta: best_45by_45roleIKR1cmRzyiMeta || {},
    alias: best_45by_45roleIKR1cmRzyiMeta?.alias || [],
    redirect: best_45by_45roleIKR1cmRzyiMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/best-by-role.vue").then(m => m.default || m)
  },
  {
    name: indexObHGO5SpWPMeta?.name ?? "candidates___es",
    path: indexObHGO5SpWPMeta?.path ?? "/candidates",
    meta: indexObHGO5SpWPMeta || {},
    alias: indexObHGO5SpWPMeta?.alias || [],
    redirect: indexObHGO5SpWPMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: indexObHGO5SpWPMeta?.name ?? "candidates___en",
    path: indexObHGO5SpWPMeta?.path ?? "/en/candidates",
    meta: indexObHGO5SpWPMeta || {},
    alias: indexObHGO5SpWPMeta?.alias || [],
    redirect: indexObHGO5SpWPMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: scheduledO3ZKUb3oLRMeta?.name ?? "demo-scheduled___es",
    path: scheduledO3ZKUb3oLRMeta?.path ?? "/demo/scheduled",
    meta: scheduledO3ZKUb3oLRMeta || {},
    alias: scheduledO3ZKUb3oLRMeta?.alias || [],
    redirect: scheduledO3ZKUb3oLRMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/demo/scheduled.vue").then(m => m.default || m)
  },
  {
    name: scheduledO3ZKUb3oLRMeta?.name ?? "demo-scheduled___en",
    path: scheduledO3ZKUb3oLRMeta?.path ?? "/en/demo/scheduled",
    meta: scheduledO3ZKUb3oLRMeta || {},
    alias: scheduledO3ZKUb3oLRMeta?.alias || [],
    redirect: scheduledO3ZKUb3oLRMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/demo/scheduled.vue").then(m => m.default || m)
  },
  {
    name: indexaX3nWQC4jEMeta?.name ?? "index___es",
    path: indexaX3nWQC4jEMeta?.path ?? "/",
    meta: indexaX3nWQC4jEMeta || {},
    alias: indexaX3nWQC4jEMeta?.alias || [],
    redirect: indexaX3nWQC4jEMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexaX3nWQC4jEMeta?.name ?? "index___en",
    path: indexaX3nWQC4jEMeta?.path ?? "/en",
    meta: indexaX3nWQC4jEMeta || {},
    alias: indexaX3nWQC4jEMeta?.alias || [],
    redirect: indexaX3nWQC4jEMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login2fo64k5CpMMeta?.name ?? "login___es",
    path: login2fo64k5CpMMeta?.path ?? "/login",
    meta: login2fo64k5CpMMeta || {},
    alias: login2fo64k5CpMMeta?.alias || [],
    redirect: login2fo64k5CpMMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/login.vue").then(m => m.default || m)
  },
  {
    name: login2fo64k5CpMMeta?.name ?? "login___en",
    path: login2fo64k5CpMMeta?.path ?? "/en/login",
    meta: login2fo64k5CpMMeta || {},
    alias: login2fo64k5CpMMeta?.alias || [],
    redirect: login2fo64k5CpMMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutGndBA6Du8eMeta?.name ?? "logout___es",
    path: logoutGndBA6Du8eMeta?.path ?? "/logout",
    meta: logoutGndBA6Du8eMeta || {},
    alias: logoutGndBA6Du8eMeta?.alias || [],
    redirect: logoutGndBA6Du8eMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutGndBA6Du8eMeta?.name ?? "logout___en",
    path: logoutGndBA6Du8eMeta?.path ?? "/en/logout",
    meta: logoutGndBA6Du8eMeta || {},
    alias: logoutGndBA6Du8eMeta?.alias || [],
    redirect: logoutGndBA6Du8eMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: createiY7zIgapQAMeta?.name ?? undefined,
    path: createiY7zIgapQAMeta?.path ?? "/offer/create",
    meta: createiY7zIgapQAMeta || {},
    alias: createiY7zIgapQAMeta?.alias || [],
    redirect: createiY7zIgapQAMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offer/create.vue").then(m => m.default || m),
    children: [
  {
    name: index6Sbsrt160mMeta?.name ?? "offer-create___es",
    path: index6Sbsrt160mMeta?.path ?? "",
    meta: index6Sbsrt160mMeta || {},
    alias: index6Sbsrt160mMeta?.alias || [],
    redirect: index6Sbsrt160mMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offer/create/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: createiY7zIgapQAMeta?.name ?? undefined,
    path: createiY7zIgapQAMeta?.path ?? "/en/offer/create",
    meta: createiY7zIgapQAMeta || {},
    alias: createiY7zIgapQAMeta?.alias || [],
    redirect: createiY7zIgapQAMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offer/create.vue").then(m => m.default || m),
    children: [
  {
    name: index6Sbsrt160mMeta?.name ?? "offer-create___en",
    path: index6Sbsrt160mMeta?.path ?? "",
    meta: index6Sbsrt160mMeta || {},
    alias: index6Sbsrt160mMeta?.alias || [],
    redirect: index6Sbsrt160mMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offer/create/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: import8plOFF3hE6Meta?.name ?? "offer-import___es",
    path: import8plOFF3hE6Meta?.path ?? "/offer/import",
    meta: import8plOFF3hE6Meta || {},
    alias: import8plOFF3hE6Meta?.alias || [],
    redirect: import8plOFF3hE6Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offer/import.vue").then(m => m.default || m)
  },
  {
    name: import8plOFF3hE6Meta?.name ?? "offer-import___en",
    path: import8plOFF3hE6Meta?.path ?? "/en/offer/import",
    meta: import8plOFF3hE6Meta || {},
    alias: import8plOFF3hE6Meta?.alias || [],
    redirect: import8plOFF3hE6Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offer/import.vue").then(m => m.default || m)
  },
  {
    name: editCNLDK2SjREMeta?.name ?? undefined,
    path: editCNLDK2SjREMeta?.path ?? "/offers/:idoffer()/edit",
    meta: editCNLDK2SjREMeta || {},
    alias: editCNLDK2SjREMeta?.alias || [],
    redirect: editCNLDK2SjREMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: detailsC1dYINHyy1Meta?.name ?? "offers-idoffer-edit-details___es",
    path: detailsC1dYINHyy1Meta?.path ?? "details",
    meta: detailsC1dYINHyy1Meta || {},
    alias: detailsC1dYINHyy1Meta?.alias || [],
    redirect: detailsC1dYINHyy1Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue").then(m => m.default || m)
  },
  {
    name: index78DLXdEdDJMeta?.name ?? "offers-idoffer-edit___es",
    path: index78DLXdEdDJMeta?.path ?? "",
    meta: index78DLXdEdDJMeta || {},
    alias: index78DLXdEdDJMeta?.alias || [],
    redirect: index78DLXdEdDJMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: technical_45requirements5Z8U3BbifEMeta?.name ?? "offers-idoffer-edit-technical-requirements___es",
    path: technical_45requirements5Z8U3BbifEMeta?.path ?? "technical-requirements",
    meta: technical_45requirements5Z8U3BbifEMeta || {},
    alias: technical_45requirements5Z8U3BbifEMeta?.alias || [],
    redirect: technical_45requirements5Z8U3BbifEMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editCNLDK2SjREMeta?.name ?? undefined,
    path: editCNLDK2SjREMeta?.path ?? "/en/offers/:idoffer()/edit",
    meta: editCNLDK2SjREMeta || {},
    alias: editCNLDK2SjREMeta?.alias || [],
    redirect: editCNLDK2SjREMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: detailsC1dYINHyy1Meta?.name ?? "offers-idoffer-edit-details___en",
    path: detailsC1dYINHyy1Meta?.path ?? "details",
    meta: detailsC1dYINHyy1Meta || {},
    alias: detailsC1dYINHyy1Meta?.alias || [],
    redirect: detailsC1dYINHyy1Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue").then(m => m.default || m)
  },
  {
    name: index78DLXdEdDJMeta?.name ?? "offers-idoffer-edit___en",
    path: index78DLXdEdDJMeta?.path ?? "",
    meta: index78DLXdEdDJMeta || {},
    alias: index78DLXdEdDJMeta?.alias || [],
    redirect: index78DLXdEdDJMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: technical_45requirements5Z8U3BbifEMeta?.name ?? "offers-idoffer-edit-technical-requirements___en",
    path: technical_45requirements5Z8U3BbifEMeta?.path ?? "technical-requirements",
    meta: technical_45requirements5Z8U3BbifEMeta || {},
    alias: technical_45requirements5Z8U3BbifEMeta?.alias || [],
    redirect: technical_45requirements5Z8U3BbifEMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexOlV1yCsVcRMeta?.name ?? undefined,
    path: indexOlV1yCsVcRMeta?.path ?? "/offers/:idoffer()",
    meta: indexOlV1yCsVcRMeta || {},
    alias: indexOlV1yCsVcRMeta?.alias || [],
    redirect: indexOlV1yCsVcRMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsp4N7MopTWaMeta?.name ?? "offers-idoffer-index-details___es",
    path: detailsp4N7MopTWaMeta?.path ?? "details",
    meta: detailsp4N7MopTWaMeta || {},
    alias: detailsp4N7MopTWaMeta?.alias || [],
    redirect: detailsp4N7MopTWaMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/details.vue").then(m => m.default || m)
  },
  {
    name: indexLQkSq6ehH6Meta?.name ?? "offers-idoffer-index___es",
    path: indexLQkSq6ehH6Meta?.path ?? "",
    meta: indexLQkSq6ehH6Meta || {},
    alias: indexLQkSq6ehH6Meta?.alias || [],
    redirect: indexLQkSq6ehH6Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/index.vue").then(m => m.default || m)
  },
  {
    name: pipelinewuRQglROUXMeta?.name ?? "offers-idoffer-index-pipeline___es",
    path: pipelinewuRQglROUXMeta?.path ?? "pipeline",
    meta: pipelinewuRQglROUXMeta || {},
    alias: pipelinewuRQglROUXMeta?.alias || [],
    redirect: pipelinewuRQglROUXMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue").then(m => m.default || m)
  },
  {
    name: searchs7pxfWV8YbMeta?.name ?? "offers-idoffer-index-search___es",
    path: searchs7pxfWV8YbMeta?.path ?? "search",
    meta: searchs7pxfWV8YbMeta || {},
    alias: searchs7pxfWV8YbMeta?.alias || [],
    redirect: searchs7pxfWV8YbMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexOlV1yCsVcRMeta?.name ?? undefined,
    path: indexOlV1yCsVcRMeta?.path ?? "/en/offers/:idoffer()",
    meta: indexOlV1yCsVcRMeta || {},
    alias: indexOlV1yCsVcRMeta?.alias || [],
    redirect: indexOlV1yCsVcRMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsp4N7MopTWaMeta?.name ?? "offers-idoffer-index-details___en",
    path: detailsp4N7MopTWaMeta?.path ?? "details",
    meta: detailsp4N7MopTWaMeta || {},
    alias: detailsp4N7MopTWaMeta?.alias || [],
    redirect: detailsp4N7MopTWaMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/details.vue").then(m => m.default || m)
  },
  {
    name: indexLQkSq6ehH6Meta?.name ?? "offers-idoffer-index___en",
    path: indexLQkSq6ehH6Meta?.path ?? "",
    meta: indexLQkSq6ehH6Meta || {},
    alias: indexLQkSq6ehH6Meta?.alias || [],
    redirect: indexLQkSq6ehH6Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/index.vue").then(m => m.default || m)
  },
  {
    name: pipelinewuRQglROUXMeta?.name ?? "offers-idoffer-index-pipeline___en",
    path: pipelinewuRQglROUXMeta?.path ?? "pipeline",
    meta: pipelinewuRQglROUXMeta || {},
    alias: pipelinewuRQglROUXMeta?.alias || [],
    redirect: pipelinewuRQglROUXMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue").then(m => m.default || m)
  },
  {
    name: searchs7pxfWV8YbMeta?.name ?? "offers-idoffer-index-search___en",
    path: searchs7pxfWV8YbMeta?.path ?? "search",
    meta: searchs7pxfWV8YbMeta || {},
    alias: searchs7pxfWV8YbMeta?.alias || [],
    redirect: searchs7pxfWV8YbMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/offers/[idoffer]/index/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: password_45resetrS581YoNUiMeta?.name ?? "password-reset___es",
    path: password_45resetrS581YoNUiMeta?.path ?? "/password-reset",
    meta: password_45resetrS581YoNUiMeta || {},
    alias: password_45resetrS581YoNUiMeta?.alias || [],
    redirect: password_45resetrS581YoNUiMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetrS581YoNUiMeta?.name ?? "password-reset___en",
    path: password_45resetrS581YoNUiMeta?.path ?? "/en/password-reset",
    meta: password_45resetrS581YoNUiMeta || {},
    alias: password_45resetrS581YoNUiMeta?.alias || [],
    redirect: password_45resetrS581YoNUiMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: indexhmOGlGIOf9Meta?.name ?? "register___es",
    path: indexhmOGlGIOf9Meta?.path ?? "/register",
    meta: indexhmOGlGIOf9Meta || {},
    alias: indexhmOGlGIOf9Meta?.alias || [],
    redirect: indexhmOGlGIOf9Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexhmOGlGIOf9Meta?.name ?? "register___en",
    path: indexhmOGlGIOf9Meta?.path ?? "/en/register",
    meta: indexhmOGlGIOf9Meta || {},
    alias: indexhmOGlGIOf9Meta?.alias || [],
    redirect: indexhmOGlGIOf9Meta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: password_45setupaHJ7neEt5eMeta?.name ?? "register-password-setup___es",
    path: password_45setupaHJ7neEt5eMeta?.path ?? "/register/password-setup",
    meta: password_45setupaHJ7neEt5eMeta || {},
    alias: password_45setupaHJ7neEt5eMeta?.alias || [],
    redirect: password_45setupaHJ7neEt5eMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/register/password-setup.vue").then(m => m.default || m)
  },
  {
    name: password_45setupaHJ7neEt5eMeta?.name ?? "register-password-setup___en",
    path: password_45setupaHJ7neEt5eMeta?.path ?? "/en/register/password-setup",
    meta: password_45setupaHJ7neEt5eMeta || {},
    alias: password_45setupaHJ7neEt5eMeta?.alias || [],
    redirect: password_45setupaHJ7neEt5eMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/register/password-setup.vue").then(m => m.default || m)
  },
  {
    name: profile_45setup3PUTPOtDACMeta?.name ?? "register-profile-setup___es",
    path: profile_45setup3PUTPOtDACMeta?.path ?? "/register/profile-setup",
    meta: profile_45setup3PUTPOtDACMeta || {},
    alias: profile_45setup3PUTPOtDACMeta?.alias || [],
    redirect: profile_45setup3PUTPOtDACMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/register/profile-setup.vue").then(m => m.default || m)
  },
  {
    name: profile_45setup3PUTPOtDACMeta?.name ?? "register-profile-setup___en",
    path: profile_45setup3PUTPOtDACMeta?.path ?? "/en/register/profile-setup",
    meta: profile_45setup3PUTPOtDACMeta || {},
    alias: profile_45setup3PUTPOtDACMeta?.alias || [],
    redirect: profile_45setup3PUTPOtDACMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/register/profile-setup.vue").then(m => m.default || m)
  },
  {
    name: buyejhf227qrJMeta?.name ?? "subscription-buy___es",
    path: buyejhf227qrJMeta?.path ?? "/subscription/buy",
    meta: buyejhf227qrJMeta || {},
    alias: buyejhf227qrJMeta?.alias || [],
    redirect: buyejhf227qrJMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/buy.vue").then(m => m.default || m)
  },
  {
    name: buyejhf227qrJMeta?.name ?? "subscription-buy___en",
    path: buyejhf227qrJMeta?.path ?? "/en/subscription/buy",
    meta: buyejhf227qrJMeta || {},
    alias: buyejhf227qrJMeta?.alias || [],
    redirect: buyejhf227qrJMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/buy.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodFleoxNjRAaMeta?.name ?? "subscription-payment-method___es",
    path: payment_45methodFleoxNjRAaMeta?.path ?? "/subscription/payment-method",
    meta: payment_45methodFleoxNjRAaMeta || {},
    alias: payment_45methodFleoxNjRAaMeta?.alias || [],
    redirect: payment_45methodFleoxNjRAaMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/payment-method.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodFleoxNjRAaMeta?.name ?? "subscription-payment-method___en",
    path: payment_45methodFleoxNjRAaMeta?.path ?? "/en/subscription/payment-method",
    meta: payment_45methodFleoxNjRAaMeta || {},
    alias: payment_45methodFleoxNjRAaMeta?.alias || [],
    redirect: payment_45methodFleoxNjRAaMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/payment-method.vue").then(m => m.default || m)
  },
  {
    name: successfulsdY84ZSlxlMeta?.name ?? "subscription-successful___es",
    path: successfulsdY84ZSlxlMeta?.path ?? "/subscription/successful",
    meta: successfulsdY84ZSlxlMeta || {},
    alias: successfulsdY84ZSlxlMeta?.alias || [],
    redirect: successfulsdY84ZSlxlMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/successful.vue").then(m => m.default || m)
  },
  {
    name: successfulsdY84ZSlxlMeta?.name ?? "subscription-successful___en",
    path: successfulsdY84ZSlxlMeta?.path ?? "/en/subscription/successful",
    meta: successfulsdY84ZSlxlMeta || {},
    alias: successfulsdY84ZSlxlMeta?.alias || [],
    redirect: successfulsdY84ZSlxlMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/successful.vue").then(m => m.default || m)
  },
  {
    name: updateS6a58Nu8pyMeta?.name ?? "subscription-update___es",
    path: updateS6a58Nu8pyMeta?.path ?? "/subscription/update",
    meta: updateS6a58Nu8pyMeta || {},
    alias: updateS6a58Nu8pyMeta?.alias || [],
    redirect: updateS6a58Nu8pyMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/update.vue").then(m => m.default || m)
  },
  {
    name: updateS6a58Nu8pyMeta?.name ?? "subscription-update___en",
    path: updateS6a58Nu8pyMeta?.path ?? "/en/subscription/update",
    meta: updateS6a58Nu8pyMeta || {},
    alias: updateS6a58Nu8pyMeta?.alias || [],
    redirect: updateS6a58Nu8pyMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/subscription/update.vue").then(m => m.default || m)
  },
  {
    name: indexIHIzZjG7CoMeta?.name ?? "users___es",
    path: indexIHIzZjG7CoMeta?.path ?? "/users",
    meta: indexIHIzZjG7CoMeta || {},
    alias: indexIHIzZjG7CoMeta?.alias || [],
    redirect: indexIHIzZjG7CoMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexIHIzZjG7CoMeta?.name ?? "users___en",
    path: indexIHIzZjG7CoMeta?.path ?? "/en/users",
    meta: indexIHIzZjG7CoMeta || {},
    alias: indexIHIzZjG7CoMeta?.alias || [],
    redirect: indexIHIzZjG7CoMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.path ?? "/profile",
    meta: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta || {},
    alias: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.alias || [],
    redirect: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.path ?? "/comprar-suscripcion",
    meta: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta || {},
    alias: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.alias || [],
    redirect: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.path ?? "/offers",
    meta: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta || {},
    alias: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.alias || [],
    redirect: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468ZL7XUHQxlxMeta?.redirect,
    component: () => import("/codebuild/output/src1250695087/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]